import React from 'react';
import "../styles/Services.css";

export function ServicesCard(props) {
    const style = {
        backgroundImage: "url(" + props.bgImage + ")",
    }
    return (
        <>

        <div className="service-card" style={style}>
            <div className="service-card-text">
                {props.name}
            </div>
        </div>
        
        </>
    )
};

