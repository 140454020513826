import React from 'react';
// import Warehouse from '../assets/warehouse.png';
import { List } from 'semantic-ui-react';
// import { makeStyles } from '@material-ui/core/styles';
import '../styles/DigitalSolutions.css';
import { Breadcrumb } from 'semantic-ui-react';
// import WarehousingCarousel from '../components/TruckingCarousel';
import WarehouseCarousel from '../components/WarehouseCarousel';





const breadcrumbSections = [
  { key: 'home', content: 'HOME', href: "/" },
  { key: 'services', content: 'SERVICES', href: "/services" },
  { key: 'warehousing', content: 'WAREHOUSING', active: true },
]


function Warehousing() {


  return (
    <>
      <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
        <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
      </div>

      <div className="services-header">Warehousing</div>
      <div className="container-fluid services-container" id="warehousing-container">
        <div class="row">
          <div className="col-lg-6 col-md-12">
            <div className="services-picture">
                <WarehouseCarousel />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="services-text">
              <h3>TITANIUM OFFERS YOU AN ARRAY OF SERVICES INCLUDING:</h3>
              < br />
              <ul>
                <List.Item as='li'>Warehousing available at many of our terminals </List.Item>
                <List.Item as='li'>Order management, fulfillment and visibility through our online custom warehouse management system</List.Item>
                <List.Item as='li'>Digital scanning and inventory management</List.Item>
                <List.Item as='li'>Shipment consolidation/de-consolidation</List.Item>
                <List.Item as='li'>Cross docking, pick and ship programs</List.Item>
                <List.Item as='li'>Pick N Pack, packaging, skid and box picks</List.Item>
                <List.Item as='li'>24-hour surveillance and security</List.Item>
                <List.Item as='li'>Reverse logistics, refurbished and restock processes</List.Item>
                <List.Item as='li'>Inventory control, lot & date management, and bar code labelling</List.Item>
                <List.Item as='li'>Certified an NHP (Natural Health Product) warehouse</List.Item>
                <List.Item as='li'>Security system and inventory control processes and disciplines</List.Item>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/** 
            <div className="trucking-container">
               
                    <div className="image-box">
                        <img src={Warehouse} />
                    </div>
                    
                    <div className="text-rectangle">
                        <img src={TextRectangle} />
                        
                            
                        <div className="bullet-list">
                            <br />
                            <b>Benefits of Titanium Warehousing includes:</b>                  
                            <ul>
                                <List.Item as='li'>Inventory reporting at the SKU level</List.Item>
                                <List.Item as='li'>Order management and fulfillment</List.Item>
                                <List.Item as='li'>Shipment consolidation/de-consolidation</List.Item>
                                <List.Item as='li'>Cross docking, pick and ship programs</List.Item>
                                <List.Item as='li'>Pick N Pack, packaging, skid and box picks</List.Item>
                                <List.Item as='li'>24-hour surveillance</List.Item>
                                <List.Item as='li'>Reverse logistics, refurbished and restock processes</List.Item>
                                <List.Item as='li'>Inventory control, lot & date management, and bar code labelling</List.Item>
                                <List.Item as='li'>Certified an NHP (Natural Health Product) warehouse by Health Canada</List.Item>
                                <List.Item as='li'>Security system and inventory control processes and disciplines</List.Item>
                                <List.Item as='li'>Inventory reporting at the SKU level via the web, from our WMS</List.Item>
                                <List.Item as='li'>Documented Processes unique to each client</List.Item>
                                <List.Item as='li'>Line of sight visibility through the life of the order</List.Item>
                                <List.Item as='li'>Radio Frequency (RF) bar code scanning </List.Item>
                            </ul>
                        </div>
                    </div>
                    
            </div>
          */}
    </>
  )
}

export default Warehousing
