import React, { useState, useEffect } from "react";
import '../styles/mobileApp.css';
import playstore from '../assets/playstore.png';
import mobileLogo from "../assets/titanium_trace_icon.png"
import appstore from "../assets/appstore.png"
import phonepromo from "../assets/store_promo.png"
import phonelogo from "../assets/phone.png"
import {Form, Row, Col, Container, Alert } from 'react-bootstrap'
import {useLocation} from "react-router-dom";



function MobileApp() {

    const [deviceType, setDeviceType] = useState('');
    // const [urlCopied, seturlCopyStatus] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [driverToken, setDriverToken] = useState('');
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [success, setSuccess] = useState({});

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search)

    const detectDeviceType = () => {
        const userAgent = navigator.userAgent;
        if (/android/i.test(userAgent)){
             setDeviceType('android');
        }
        else if (/iPad|iPhone|iPod/i.test(userAgent)){
            setDeviceType('ios');
        }
        else {
            setDeviceType('not mobile');
        }
    }

    useEffect(() => {
        detectDeviceType();
        setDriverToken(searchParams.get('token'));
        setOrderNumber(searchParams.get('order'));
    }, []);

    const copyURL = () => {
        var dummy = document.createElement('input'),
        text = window.location.href;

        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    }

    const appstoreOnClick = () => {
        copyURL()
        
        window.location = "https://apps.apple.com/app/titanium-trace/id1603288617"
    }

    const openApp = () => {
        const url = new URL(window.location.href);
        // pass the parameter 
        window.location = "titaniumapp://" + url.search
    }

    const playstoreOnClick = () => {
        copyURL();
        window.location = "https://play.google.com/store/apps/details?id=com.ttgi.titaniumtrace"
    }

    // const copyClicked = () => {
    //     copyURL();
    //     seturlCopyStatus(true);
    // }

    const orderNumberOnChange = (e) => {
        e.preventDefault();
        let curValue = e.target.value;
        setOrderNumber(curValue.replace(/[^\d]/g, ''));
    }

    
    const isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };
    
    
    const formatToPhone = (event) => {
        if(isModifierKey(event)) {return;}
    
        const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
        const areaCode = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);
        if(input.length > 6){setPhoneNumber(`(${areaCode}) ${middle}-${last}`);}
        else if(input.length > 3){setPhoneNumber(`(${areaCode}) ${middle}`);}
        else if(input.length > 0){setPhoneNumber(`(${areaCode}`);}
        else {setPhoneNumber('')}
    };

    const sendTextOnClick = (e) => {
        e.preventDefault();
        if (!(orderNumber && phoneNumber)){
            setError({header: "All Fields Are Required!"});
            setShowError(true);
        }
        if (phoneNumber.length < 14) {
            setError({header: "Invalid Phone Number!"});
            setShowError(true);
        }
        const postBody = {
            "driver_token": driverToken,
            "order_number": parseInt(orderNumber),
            "phone_number": phoneNumber
        }


        fetch("https://api.ttgi.com/message/send", {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/text',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(postBody)
        })
        .then(res => {
            if (res.status !== 200){
                setError({header: "Invalid order number/token."});
                setShowError(true);
            } else {
                setSuccess({header: "Text message sent successfully!"});
                setShowSuccess(true);
            }
        })
        .catch(_ => {
            setError({header: "Failed. Please try again later or check your order number/phone number."});
            setShowError(true);
        });

    }

    return (
        <>
        <div id="container">
            
            <div id="color-section">
                <div id="download-info-wrap">
                    {/* <img id="mobileLogo" src={mobileLogo} alt="App logo"/> */}
                    <p id="download-header">DRIVERS, DOWNLOAD TITANIUM TRACE</p>
                    <p id="download-body">Titanium requires all loads to be tracked. Please have your driver install our app on their mobile phone.</p>
                    
                    <div id="badge-wrap">
                    {(deviceType === 'android' || deviceType === 'not mobile') && 
                        <img className="badge-logo badge-button" src={playstore} alt="Playstore" onClick={playstoreOnClick}/>}
                    {(deviceType === 'ios'  || deviceType === 'not mobile') && 
                        <img className="badge-logo badge-button" src={appstore} alt="App Store" onClick={appstoreOnClick}/>}
                    </div>
                    {(deviceType === 'ios'  || deviceType === 'android') && 
                    <div id="have-app">
                        <p id="app-label"> Have the app already? </p>
                        <a className="openapp" onClick={openApp}> Open in App </a>
                    </div>}
                </div>
                <div id="phone-display-section">
                    <img id="phonepromo" src={phonepromo} alt="Display App Screen"></img>
                    <img id="phonelogo" src={phonelogo} alt="Display App Screen"></img>
                </div>
            </div>
            <div id="text-section">
                <p id="text-header">CARRIERS, TEXT THIS LINK TO YOUR DRIVER</p>
                <p id="text-body">For security purpose, please enter the order number as well as your driver's phone number.</p>
                <Container className="justify-content-center">
                    {driverToken && 
                    <Row className="justify-content-center">
                        <Col className="col-6 col-md-4">
                        <Form.Label column="lg" className="sms-form-label">Token:</Form.Label>
                        </Col>
                        <Col className="col-6">
                            <Form.Control size="lg" className="text-center sms-input-field" type="text" placeholder="Token" value={driverToken} disabled/>
                        </Col>
                    </Row>}
                    <Row className="justify-content-center">
                        <Col className="col-6 col-md-4">
                        <Form.Label column="lg" className="sms-form-label">Order Number:</Form.Label>
                        </Col>
                        <Col className="col-6">
                            <Form.Control size="lg" className="text-center sms-input-field" type="text" placeholder="Order Number" onChange={orderNumberOnChange} value={orderNumber} required={true}/>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col className="col-6 col-md-4">
                        <Form.Label column="lg" className="sms-form-label">Driver's Phone Number:</Form.Label>
                        </Col>
                        <Col className="col-6">
                            <Form.Control size="lg" className="text-center sms-input-field" type="text" placeholder="(xxx) xxx-xxxx" onChange={formatToPhone} value={phoneNumber} required/>
                        </Col>
                    </Row>
                    {showError && 
                    <Alert id="alertbox" variant="danger" onClose={() => setShowError(false)} dismissible>
                        <Alert.Heading>{error.header}</Alert.Heading>
                    </Alert>}
                    {showSuccess && 
                    <Alert id="alertbox" variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        <Alert.Heading>{success.header}</Alert.Heading>
                    </Alert>}
                    <div>
                        <button type="submit" className={`send-sms-button`} id="send-sms-button" disabled={driverToken===''} onClick={sendTextOnClick}>Send Text</button>
                        {driverToken==='' && <div id="hover-message">Token not detected, please check the url.</div>}
                    </div>
                </Container>
                
            </div>
            
{/*             
            <div id="dispatcher-container">
                <h1 className="header"> DISPATCHERS </h1>
                <div id="url-copying-container">
                    <p className="label"> Provide This Link To Your Driver </p>
                    <input type="text" value={window.location.href} size='40' id="url-input" />
                    <button id="copy-url-button" className="store-badge-button openapp" onClick={copyClicked}>Click to Copy</button>
                    {urlCopied && <p> URL Copied!  </p>}
                </div>
            </div> */}
            
            
        </div>
        </>
    )

}

export default MobileApp
