import React, { useState, useEffect } from "react";
import '../styles/mobileApp.css';
import playstore from '../assets/playstore.png';
import mobileLogo from "../assets/titanium_trace_icon.png"
import appstore from "../assets/appstore.png"
import phonepromo from "../assets/store_promo.png"
import phonelogo from "../assets/phone.png"
import {Form, Row, Col, Container, Alert } from 'react-bootstrap'
import {useLocation} from "react-router-dom";



function MobileApp() {

    const [deviceType, setDeviceType] = useState('');
    // const [urlCopied, seturlCopyStatus] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [driverToken, setDriverToken] = useState('');

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search)

    const detectDeviceType = () => {
        const userAgent = navigator.userAgent;
        if (/android/i.test(userAgent)){
             setDeviceType('android');
        }
        else if (/iPad|iPhone|iPod/i.test(userAgent)){
            setDeviceType('ios');
        }
        else {
            setDeviceType('not mobile');
        }
    }

    useEffect(() => {
        detectDeviceType();
        setDriverToken(searchParams.get('token'));
        setOrderNumber(searchParams.get('order'));
    }, []);

    const copyURL = () => {
        var dummy = document.createElement('input'),
        text = window.location.href;

        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    }

    const appstoreOnClick = () => {
        copyURL()
        
        window.location = "https://apps.apple.com/app/titanium-trace/id1603288617"
    }

    const openApp = () => {
        const url = new URL(window.location.href);
        // pass the parameter 
        window.location = "titaniumapp://" + url.search
    }

    const playstoreOnClick = () => {
        copyURL();
        window.location = "https://play.google.com/store/apps/details?id=com.ttgi.titaniumtrace"
    }

    const orderNumberOnChange = (e) => {
        e.preventDefault();
        let curValue = e.target.value;
        setOrderNumber(curValue.replace(/[^\d]/g, ''));
    }

    return (
        <>
        <div id="container">
            
            <div id="color-section">
                <div id="download-info-wrap">
                    {/* <img id="mobileLogo" src={mobileLogo} alt="App logo"/> */}
                    <p id="download-header">PLEASE DOWNLOAD TITANIUM TRACE!</p>
                        {/*<p id="download-body">Titanium requires all loads to be tracked. Please have your driver install our app on their mobile phone.</p>*/}
                    <p id="download-body">Titanium & our customer requires this load to be tracked. As the assigned driver of this load, we kindly ask you to install our app so we can track its progress.</p>
                    
                    <div id="badge-wrap">
                    {(deviceType === 'android' || deviceType === 'not mobile') && 
                        <img className="badge-logo badge-button" src={playstore} alt="Playstore" onClick={playstoreOnClick}/>}
                    {(deviceType === 'ios'  || deviceType === 'not mobile') && 
                        <img className="badge-logo badge-button" src={appstore} alt="App Store" onClick={appstoreOnClick}/>}
                    </div>
                    {(deviceType === 'ios'  || deviceType === 'android') && 
                    <div id="have-app">
                        <p id="app-label"> Have the app already? </p>
                        <a className="openapp" onClick={openApp}> Open in App </a>
                    </div>}
                </div>
                <div id="phone-display-section">
                    <img id="phonepromo" src={phonepromo} alt="Display App Screen"></img>
                    <img id="phonelogo" src={phonelogo} alt="Display App Screen"></img>
                </div>
            </div>
        </div>
        </>
    )

}

export default MobileApp
