import React from 'react';
import '../styles/Trucking.css';
import { List } from 'semantic-ui-react';
// import Truck from '../assets/truck-image.png';
// import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumb } from 'semantic-ui-react';
import TruckingCarousel from '../components/TruckingCarousel';

const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'services', content: 'SERVICES', href: "/services" },
    { key: 'trucking', content: 'TRUCKING', active: true },
]


// function handleClick(event) {
//     event.preventDefault();
// }


function Trucking() {



    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff', fontDecoration: 'none' }} />
            </div>

            <div className="services-header">Trucking</div>

            <div className="container-fluid services-container" id="trucking-container">
                <div class="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="services-picture">
                            <TruckingCarousel />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="services-text">
                            <h3>TITANIUM UTILIZES ADVANCED TECHNOLOGY TO ENHANCE OPERATIONS:</h3>
                            < br />
                            <ul>
                                <List.Item as='li'>Fleet of 800+ power units, 2900 trailers across Ontario</List.Item>
                                <List.Item as='li'>Flatbed trailers with heavy axle specialized services</List.Item>
                                <List.Item as='li'>Dry and temperature controlled vans, multi-axle, as well as reefers and tankers</List.Item>
                                <List.Item as='li'>Isaac Instruments ELDs for truck tracking, communications, and workflow management</List.Item>
                                <List.Item as='li'>Blackberry RADAR for GPS trailer tracking, load capacity status and environment monitoring</List.Item>
                                <List.Item as='li'>24/7 dispatch and real time in-cab communications</List.Item>
                                <List.Item as='li'>Load connectivity with our customers using EDI and API</List.Item>
                                <List.Item as='li'>Available customer portal for load tracking</List.Item>
                                <List.Item as='li'>CSA, PIP, and C-TPAT certified</List.Item>
                                <List.Item as='li'>SmartWay Certified "green" carrier</List.Item>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>

            {/**
            <div className="trucking-container">
                <div className="trucking-header">Trucking</div>

                    <div className="image-box">
                        <img src={Truck} />
                    </div>
                    
                    <div className="text-rectangle">
                        <img src={TextRectangle} />
                        
                            
                        <div className="bullet-list">
                            <br />
                            <b>TITANIUM OFFERS YOU SERVICES WITH THE FOLLOWING ADVANTAGES:</b>                  
                            <ul>
                                <List.Item as='li'>Fleet of 3000 trailers and 800 power units</List.Item>
                                <List.Item as='li'>Flatbed trailers , dry vans, step-decks, heavy axle trailers, reefers, and < br />tankers</List.Item>
                                <List.Item as='li'>Satellite tracking on highway units for instant communication</List.Item>
                                <List.Item as='li'>24/7 centralized dispatch and in-cab communications</List.Item>
                                <List.Item as='li'>Experienced quality drivers, dedicated to our customers</List.Item>
                                <List.Item as='li'>Competitive rates</List.Item>
                                <List.Item as='li'>Fleet equipped with the latest technology to track and measure capacity</List.Item>
                                <List.Item as='li'>CSA, PIP, ACE/ACI, EDI certified</List.Item>
                                <List.Item as='li'>S-TPAT certified partner</List.Item>
                                <List.Item as='li'>Smart certified "Green" carrier</List.Item>
                                <List.Item as='li'>Temperature controlled shipping</List.Item>
                            </ul>
                        </div>
                    </div>
                    
            </div>
         */}
        </>
    )
}

export default Trucking
