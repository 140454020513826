import React, { useState } from 'react';
import '../styles/RequestQuote.css'                                                                                                                                                                                                                                                                                                                       
// import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumb } from 'semantic-ui-react';

require('dotenv').config();

//// Load the AWS SDK for Node.js
//var AWS = require("aws-sdk");

//const SES_CONFIG = {
//    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
//    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//    region: 'us-east-1',
//};

//const AWS_SES = new AWS.SES(SES_CONFIG);

const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'requestquote', content: 'REQUEST A QUOTE', active: true },
  ]


// function handleClick(event) {
//   event.preventDefault();
// }




function RequestQuote() {
    
    {/**breadcrumb */}
    // const useStyles = makeStyles((theme) => ({
    //     root: {
    //     '& > * + *': {
    //         marginTop: theme.spacing(2), 
    //     },
    //     },
    // }));


  const [subscriptionFormMessage, setSubscriptionFormMessage] = useState("");

//   function sendEmail(e) {
//       window.alert("email sent")
//   }

  function sendEmail(e) {
      //https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/ses-examples-sending-email.html
      //https://betterprogramming.pub/how-to-send-emails-with-node-js-using-amazon-ses-8ae38f6312e4

      e.preventDefault();
      setSubscriptionFormMessage("");

      var emailBody = `${e.target.company.value} ${e.target.firstName.value} ${e.target.lastName.value} ${e.target.email.value} ${e.target.jobTitle.value} ${e.target.mainOffice.value} ${e.target.industry.value}${e.target.shipping.value}${e.target.oftenFreight.value}`;

      let body = {
          "subject": "Quote Request",
          "body": emailBody,
          "recipient": "steffen.hem@ttgi.com;rob.thomas@ttgi.com;jim.mcwhirter@ttgi.com"
      }

      // create the headers for the fetch 
      const myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Content-Type', 'application/json');

      // add the orders via api call
      fetch('https://api.ttgi.com/wwwEmail/send', {
          headers: myHeaders,
          method: "POST",
          body: JSON.stringify(body)
      }).then((response) => {
          //set the error message
          if (response.status != 200) {
              console.log(response);
          }
          return response.json();
      })
          .then(data => {
              setSubscriptionFormMessage('Request successfully sent!');
          })
          .catch(function (error) {
              setSubscriptionFormMessage('There was an error processing your request. Please try again later or contact dev@ttgi.com.');
          });

      //// Create sendEmail params 
      //var params = {
      //    Destination: { /* required */
      //        CcAddresses: [
      //          //   'dev@ttgi.com',
      //            /* more items */
      //        ],
      //        ToAddresses: [
      //          //   'rob.thomas@ttgi.com'
      //          'steffen.hem@ttgi.com'
      //            /* more items */
      //        ]
      //    },
      //    Message: { /* required */
      //        Body: { /* required */
      //            Html: {
      //                Charset: "UTF-8",
      //                Data: emailBody
      //            },
      //            Text: {
      //                Charset: "UTF-8",
      //                Data: emailBody
      //            }
      //        },
      //        Subject: {
      //            Charset: 'UTF-8',
      //            Data: 'Quote Request'
      //        }
      //    },
      //    Source: 'donotreply@ttgi.com', /* required */
      //    ReplyToAddresses: [
      //        'donotreply@ttgi.com',
      //        /* more items */
      //    ],
      //};

      //// Create the promise and SES service object
      //var sendPromise = AWS_SES.sendEmail(params).promise();

      //// Handle promise's fulfilled/rejected states
      //sendPromise.then(
      //    function (data) {
      //        console.log(data.MessageId);
      //        window.alert("Request successfully sent!")
      //        setSubscriptionFormMessage('Request successfully sent!');
      //    }).catch(
      //        function (err) {
      //            setSubscriptionFormMessage('There was an error processing your request. Please try again later or contact investors@ttgi.com.');
      //            console.error(err, err.stack);
      //            window.alert("There was an error processing your request. Please try again later or contact investors@ttgi.com.")
      //        });

      e.target.reset();
  }

    return (

        <> 
            <div style={{textAlign: 'left', marginLeft: '70px', marginTop: '80px'}}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{backgroundColor: '#fff'}} />
            </div>
            <div className="quote-header">Request a Quote</div>

            <div className="quote-form" >
                <form onSubmit={sendEmail}> 
                    <ul className="form-style-1" >
                        <li>
                        <label>Company *<span className="required"></span></label>
                        <input type="text" required="required"  name="company" className="field-long" />
                        </li>

                        <li>
                            <label>First Name * <span className="required" ></span></label>
                            <input type="text" required="required"  name="firstName" className="field-long" />
                        </li>

                        <li>
                            <label>Last Name * <span className="required" ></span></label>
                            <input type="text" required="required" name="lastName" className="field-long" />
                        </li>
                        <li>
                            <label>Email <span className="required"></span></label>
                            <input type="email" name="email" className="field-long" />
                        </li>

                        <li>
                            <label>Job Title <span className="required"></span></label>
                            <input type="text" name="jobTitle" className="field-long" />
                        </li>

                        <li>
                            <label>Main Office/Warehouse Location(s) *<span className="required"></span></label>
                            <input type="text" required="required"  name="mainOffice" className="field-long"/>
                        </li>

                        <li>
                            <label>Industry/Commodity<span className="required"></span></label>
                            <input type="text" name="industry" className="field-long"/>
                        </li>

                        <li>
                            <label>Where do you ship? *<span className="required"></span></label>
                            <input type="text" required="required"  name="shipping" className="field-long"/>
                        </li>

                        <li>
                            <label>How often do you have freight to move? <span className="required"></span></label>
                            <input type="text" name="oftenFreight" className="field-long"/>
                        </li>



                        <input type="submit" value="REQUEST QUOTE" />
                    
                    </ul>
                </form>
               
            </div>
         
    
            
        </>
     )

}





export default RequestQuote

