import { SideMenuData } from './SideMenuData';
import "../styles/SideMenu.css";
import React from "react";
import SideMenuItemContainer from "./SideMenuItemContainer"



function SideMenu(props) {
    return (
        <>
 
            <nav className={props.sidebarOpen ? 'nav-menu active' : 'nav-menu'}>
                <SideMenuItemContainer mainItems = {SideMenuData} sidebarOpen={props.sidebarOpen} toggleSidebar={props.toggleSidebar}/>
            </nav>

        </>
    );
}
export default SideMenu
