import React from 'react';
import Trucking from '../assets/trucking.png';
import Logistics from '../assets/logistics.jpg'
import Warehousing from '../assets/warehousing.jpg'
import Digital from '../assets/connectivity.jpg'
import { Link } from 'react-router-dom';
import "../styles/Services.css";
// import { makeStyles } from '@material-ui/core/styles';
import '../styles/DigitalSolutions.css';
import { Breadcrumb } from 'semantic-ui-react';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Typography from '@material-ui/core/Typography';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Container, Row, Col } from "react-bootstrap";
import { ServicesCard } from '../components/ServicesCard'

const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'services', content: 'SERVICES', active: true },
]

// function handleClick(event) {
//     event.preventDefault();
// }

function Services() {
    
    {/**breadcrumb */}
    // const useStyles = makeStyles((theme) => ({
    //     root: {
    //         '& > * + *': {
    //         marginTop: theme.spacing(2),
    //         },
    //     },
    // }));



    return (
    <>

    <div style={{textAlign: 'left', marginLeft: '70px', marginTop: '80px'}}>
        <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{backgroundColor: "#fff"}}/>
    </div>

      <div className="services-container">
            <div className="services-header">Services</div>
        
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs="auto" className="p-4">
                        <Link to="/trucking">
                            <ServicesCard bgImage={Trucking} name="TRUCKING"/>
                        </Link>
                    </Col>
                    <Col xs="auto" className="p-4">
                        <Link to="/logistics">
                            <ServicesCard bgImage={Logistics} name="LOGISTICS"/>
                        </Link>
                    </Col>
                    <Col xs="auto" className="p-4">
                        <Link to="/warehousing">
                            <ServicesCard bgImage={Warehousing} name="WAREHOUSING"/>
                        </Link>
                    </Col>
                    <Col xs="auto" className="p-4">
                        <Link to="/digitalsolutions">
                            <ServicesCard bgImage={Digital} name="CONNECTIVITY"/>
                        </Link>
                    </Col>

                </Row>
            </Container>
      </div>
    </>
       
    );
}

export default Services;
