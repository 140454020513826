import TALIClaimForm from '../assets/TALI_Claim_Form.pdf';
import TLIClaimForm from '../assets/TLI_Claim_Form.pdf';
import TTSIClaimForm from '../assets/TTSI_Claims_Form.pdf';
import TATIClaimForm from '../assets/TATI_Claim_Form.pdf';
import Modal from 'react-modal';


function ClaimModal({ isOpen, onClose }) {
    // styling modal 
    const customStyles = {
        content: {
            position: "fixed",
            //display: "flex",
            justifyContent: "center",
            top: "35%",
            left: "38%",
            width: '20%',
            height: '200px',
            zIndex: "1001",
            overflowY: "auto"
        },
    }; // Keep your modal styles here.
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
            style={customStyles}
        >
            <div className="container h-100">
                <div className="row h-100 justify-content-center">
                    <h1>Select your claim</h1>
                    <a href={TALIClaimForm} target="_blank">Titanium American Logistics Inc.</a>
                    <a href={TATIClaimForm} target="_blank">Titanium American Trucking Inc.</a>
                    <a href={TLIClaimForm} target="_blank">Titanium Logistics Inc.</a>
                    <a href={TTSIClaimForm} target="_blank">Titanium Trucking Services Inc.</a>
                </div>
            </div>
        </Modal>
    );
}

export default ClaimModal;
