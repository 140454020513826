import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../styles/DividendInformation.css';
import { Breadcrumb } from 'semantic-ui-react';
import { Container, Col, Row  } from 'react-bootstrap';


const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'investors', content: 'INVESTORS', href: "/investors" },
    { key: 'dividendinformation', content: 'DIVIDEND INFORMATION', active: true }
 
 ]

const mostRecent = [    
    {
        recordDate: "August 31, 2022",
        paymentDate: "September 15, 2022",
        dividend: "0.02"
    },

    {
        recordDate: "May 31, 2022",
        paymentDate: "June 15, 2022",
        dividend: "0.02"
    },
    {
        recordDate: "February 28, 2022",
        paymentDate: "March 15, 2022",
        dividend: "0.02"
    },
    {
        recordDate: "November 30, 2021",
        paymentDate: "December 15, 2021", // 2020 or 2021
        dividend: "0.02"
    },

    {
        recordDate: "August 31, 2021",
        paymentDate: "September 15, 2021",
        dividend: "0.02"
    },
    {
        recordDate: "February 26, 2021",
        paymentDate: "March 15, 2021",
        dividend: "0.02"
    },
]



function DividendInformation() {

          {/**breadcrumb */}
    const useStyles = makeStyles((theme) => ({
        root: {
        '& > * + *': {
            marginTop: theme.spacing(2), 
        },
        },
    }));
    


    return (
        <>
        <div style={{textAlign: 'left', marginLeft: '70px', marginTop: '80px'}}>
            <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{backgroundColor: '#fff'}} />
        </div>

        <div className="dinfo-header">Dividend Information</div>
        < br />
        < br />

        <div className="top-line" style={{width: '60%', marginLeft: 'auto', marginRight: 'auto', fontSize: '1rem !important'}}>The following lists the most recent dividends declared by TTGI's Board of Directors on the company's common shares.</div>
        
        < br />
        <div className="divider-middle" style={{width: '70%', backgroundColor: '#0D1643'}}><hr style={{height: '3px', color: '#0D1643', borderColor: '#0D1643', backgroundColor: '#0d1643', fontWeight: 'bold'}}/></div>
        <div className="container-text">
            
            <Container>
                <Row>
                    
                    <Col style={{fontWeight: 'bold', color: '#f78437', fontSize: '1.0625rem !important'}}>Record Date</Col>
                    <Col style={{fontWeight: 'bold', color: '#f78437', fontSize: '1.0625rem !important'}}>Payment Date</Col>
                    <Col style={{fontWeight: 'bold', color: '#f78437', fontSize: '1.0625rem !important'}}>Dividend (per share)</Col>

                </Row>
                {mostRecent.map((data, index) => {
                    return (
                        <Row>
         
                            <Col style={{fontSize: '1rem !important'}} >{data.recordDate}</Col>
                            <Col style={{fontSize: '1rem !important'}}>{data.paymentDate}</Col>
                            <Col style={{fontSize: '1rem !important'}}>{data.dividend}</Col>
                            
                        </Row>
                    )
                })}
            </Container>
        </div> 

        <div className="divider-middle" style={{width: '70%', backgroundColor: '#0D1643'}}><hr style={{height: '3px', color: '#0D1643', borderColor: '#0D1643', backgroundColor: '#0d1643', fontWeight: 'bold', marginTop: '-20px', marginBottom: '30px'}}/></div>

        <div className="middle-text" style={{width: '60%', marginLeft: 'auto', marginRight: 'auto', fontSize: '1rem !important'}}>Some dividends paid by the company were not designated as eligible dividends as they were derived from earnings of acquired or legacy companies that were
        taxed at a lower rate. The following table provides the quarterly dividends per share paid including a non-eligible portion:</div>
        
        < br />
        <div className="divider-middle" style={{width: '70%', backgroundColor: '#0D1643'}}><hr style={{height: '3px', color: '#0D1643', borderColor: '#0D1643', backgroundColor: '#0d1643', fontWeight: 'bold', marginTop: '0px', marginBottom: '20px'}}/></div>
        
        <Container style={{marginTop: '0px',}}>
                <Row>
                    <Col style={{fontWeight: 'bold', color: '#f78437', fontSize: '1.0625rem !important'}}>Payment Date</Col>
                    <Col style={{fontWeight: 'bold', color: '#f78437', fontSize: '1.0625rem !important'}}>Eligible Dividend</Col>
                    <Col style={{fontWeight: 'bold', color: '#f78437', fontSize: '1.0625rem !important'}}>Non-Eligible</Col>
                    <Col style={{fontWeight: 'bold', color: '#f78437', fontSize: '1.0625rem !important'}}>Total Dividend (per share)</Col>

                  
                </Row>
                <Row>
                    <Col style={{fontSize: '1rem !important'}} >December 15, 2020</Col>
                    <Col style={{fontSize: '1rem !important'}} >0.016</Col>
                    <Col style={{fontSize: '1rem !important'}}>0.04</Col>
                    <Col style={{fontSize: '1rem !important'}}>0.02</Col> 
                </Row>
               
            </Container>
            <div className="divider-bottom" style={{width: '70%', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#0D1643', marginTop: '-105px', marginBottom: '40px'}}><hr style={{height: '3px', color: '#0D1643', borderColor: '#0D1643', backgroundColor: '#0d1643', fontWeight: 'bold', marginTop: '130px'}}/></div> 
        </>



    
    )
}


export default DividendInformation
