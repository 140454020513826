import { Link } from "react-router-dom";


const SubMenu = (props) => {

    return (
        <>
            <ul className='nav-sub-menu-items' onClick={props.toggleSidebar}>
                {props.item.subitem.map((sub, subIdx) => {
                    return (
                        <li key={subIdx} className={sub.cName}>
                            <Link to={sub.path} size='1em'>
                                {sub.icon}
                                <span>{sub.title}</span>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </>
    );
};

export default SubMenu;
