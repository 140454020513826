import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import '../styles/MissionStatement.css';



const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'contactus', content: 'MISSION STATEMENT', active: true },
]

function MissionStatement() {
    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>

            <div className="mission-header">Mission Statement</div>
            <div className="mission-text" style={{fontWeight: 'bold', fontStyle: "italic", fontSize: "1.2em", color: "#0d1643", marginBottom: "50px"}}>
                "To provide reliable, integral and sustainable service solutions through a strong support team of transportation professionals dedicated to our customer needs 
                and committed to excellence."
            </div>

            <div className="mission-subheader">Objectives</div>
            <div className="mission-sub-subheader">Our Culture</div>
            <div className="mission-text">
                <p>
                We are committed to conducting our business in an ethical and professional manner with a commitment to protecting our customers, vendors and employees.  
                We consider our social and environmental responsibilities as part of our operations while maintaining an entrepreneurial spirit throughout the business.  
                We take a calculated approach to opportunities to create long-term value for all our shareholders.
                </p>
            </div>

            <div className="mission-sub-subheader">Our Customers</div>
            <div className="mission-text">
                <p>
                    We strive to provide premium service through a healthy balance of human touch and technology. 
                    We ensure our business partners are well integrated to our business and held to a high standard.
                </p>
            </div>
            <div className="mission-sub-subheader">Our Employees</div>
            <div className="mission-text" style={{marginBottom: '40px'}}>
                <p>
                We are committed to providing an equitable and diverse environment. 
                We continue to recruit and attract top talent in our industry and encourage our employees to grow and develop their careers through 
                education and opportunity in a meaningful way.
                </p>
            </div>

            <div className="mission-subheader">Our Values</div>
            <div className="mission-sub-subheader">Safety</div>
            <div className="mission-text">
                <p>
                    Safety is our culture and not an obligation. Our goal and commitment is to ensure a safe work environment in our offices and on the road.  
                    We strive for sustainable safety excellence.
                </p>
            </div>

            <div className="mission-sub-subheader">Teamwork</div>
            <div className="mission-text">
                <p>
                    We believe that the best results are achieved when everyone works together.  
                    As a Company, we recognize the contribution of all our team members and focus on collaborative thoughtful solutions and innovations.
                </p>
            </div>

            <div className="mission-sub-subheader">Honesty and Integrity</div>
            <div className="mission-text">
                <p>
                    Simply, we do what we say. We are honest and ethical conducting ourselves with the highest level of accountability and transparency.
                </p>
            </div>

            <div className="mission-sub-subheader">Professionalism and Excellence</div>
            <div className="mission-text" style={{marginBottom: '80px'}}>
                <p>
                    We conduct ourselves in a manner of which we are proud as individuals, and as representatives of both our company and the industry as a whole. 
                    We strive to make good decisions and deliver on our commitments. We persevere in the face of challenge with a positive attitude 
                    and a fierce determination to succeed.
                </p>
            </div>

        </>
    )
}

export default MissionStatement
