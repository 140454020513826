import { Carousel } from 'react-responsive-carousel';
//import TruckAtlanta from '../assets/AtlantaTruck.jpg';
//import Trailers from '../assets/trailers.png';
//import TruckFront from '../assets/truck-front.png'
import Warehouse1 from '../assets/warehouse1.png';
import Warehouse2 from '../assets/warehouse2.png';
import Warehouse3 from '../assets/warehouse3.png';
import '../styles/Warehousing.css';

export default () => (
    <Carousel className="carousel" autoPlay>
        <div className="ds-image-one">
            <img src={Warehouse1} alt="digital" />
        </div>
        <div className="ds-image-two">
            <img src={Warehouse2} alt="image 2" />
        </div>
        <div className="ds-image-three">
            <img src={Warehouse3} alt="Trailers" />
        </div>
    </Carousel>
)