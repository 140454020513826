import React, { useState } from "react";
import { Link } from 'react-router-dom';
import BannerImage from "../assets/Homepage_image-copy.png";
import '../styles/Home.css';
import PopupVideo from "../components/PopupVideo.js";
import { useHistory } from "react-router-dom";


function Home() {


  const history = useHistory();
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const applyOnClick = () => {
    const url = "https://recruiting.ultipro.ca/TIT5000TTG/JobBoard/78b7a01b-cbed-4d4b-93ab-0a635eaebefb/?q=&o=postedDateDesc&w=&wc=&we=&wpst=&f5=S2seuumGQkKZkh9OrNyrzA";
    window.open(url, '_blank');
  }

  const gotoLogin = () => {
    history.push("/login");
  }

  return (
    <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>

      <div className="loginContainer">
        <button onClick={gotoLogin} className="loginContainer-btn">CLICK HERE FOR CUSTOMER, CARRIER, DRIVER & WAREHOUSE LOGINS</button>
      </div>

      <div className="buttonContainer">
        <Link to="/">
          <button className="buttonContainer-quote" onClick={applyOnClick} style={{ width: '190px', fontWeight: 'bold' }}>DRIVERS APPLY HERE</button>
        </Link>
        <Link to="/">
          <button className="buttonContainer-quote" onClick={onOpenModal} style={{ width: '190px', fontWeight: 'bold' }}>WATCH OUR VIDEO</button>
        </Link>
      </div>
      {open && <PopupVideo open={open} close={onCloseModal} />}

    </div>
  );
}

export default Home
//()=> window.open("https://vimeo.com/687947777/ee08ec7b0b", "_blank")