import React from 'react';
import TedImage from '../assets/Ted.png';
import BillImage from '../assets/BillImage.png';
import DavidImage from '../assets/DavidImage.png';
import LuImage from '../assets/Lu.png';
import GraceImage from '../assets/GraceImage.png';
import '../styles/BoardOfDirectors.css';
import { Breadcrumb } from 'semantic-ui-react';
import { Container, Row } from "react-bootstrap";

const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'investors', content: 'INVESTORS', href: "/investors" },
    { key: 'board', content: 'BOARD OF DIRECTORS', active: true },
]


function BoardOfDirectors() {


    return (
        <div className="whole-container">
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>

            <div className="board-header">Board of Directors</div>

            
            <Container fluid className="board-container">

                
                <Row><div className="divider-middle"><hr /></div></Row>

                {/*TED*/}
                <Row className="justify-content-start align-items-start">
                    <div className='board-content'>
                        <div className='img-container'><img className="board-image" src={TedImage} alt="Ted Daniel" /> </div>
                        <div className='summary'>
                            <p className="name"> Ted Daniel, CA, CPA</p>
                            <p className="job-title"> CEO, President, Director & Co-Founder </p>
                            <p>
                                Since founding Titanium in 2002, Ted has led the growth of the Company from a start-up to ~$500 Million in revenue
                                including a U.S. expansion and 12 acquisitions. Prior to Titanium, Ted led multiple turnarounds and restructurings
                                as a CFO over a 10-year span, which also included a three-year tenure as CFO of a mid-sized transportation company
                                where he gained extensive knowledge of the industry. Ted is a Chartered Accountant, formerly with KPMG and SLF.
                                He holds a BA in Computer Science, and an HBBA from York University.
                            </p>
                        </div>
                    </div>
                </Row>
                <Row><div className="divider-middle"><hr /></div></Row>

                {/*LU*/}
                <Row className="justify-content-start align-items-start">
                    <div className='board-content'>
                        <div className='img-container'><img className="board-image" src={LuImage} alt="Lu Glassaso" /> </div>
                        <div className='summary'>
                            <p className="name"> Lu Galasso, B. Comm., CA, CPA</p>
                            <p className="job-title"> Chair of the Board of Directors </p>
                            <p>
                                Mr. Galasso is a partner with the Zzen Group of Companies in Vaughan, Ontario which owns and operates companies in
                                land development, real estate, services, hospitality, and manufacturing sectors. Prior to Zzen Group, he was a Vice President
                                of Royal Group and a contributing member of the Royal Group's "going public" transaction and acquisition teams.
                                Mr. Galasso completed the Directors Education Program at the Rotman School of Management and is a CPA.
                                He is the President of the Meta Foundation, a charitable organization dedicated to people with special needs,
                                and is a director and fundraiser for the St. Christopher Children's Home.
                            </p>
                        </div>
                    </div>
                </Row>
                <Row><div className="divider-middle"><hr /></div></Row>

                {/*GRACE*/}
                <Row className="justify-content-start align-items-start">
                    <div className='board-content'>
                        <div className='img-container'><img className="board-image" src={GraceImage} alt="Lu Glassaso" /> </div>
                        <div className='summary'>
                            <p className="name"> Grace Palombo</p>
                            <p className="job-title"> Chair of the Human Resources and Compensation Committee </p>
                            <p>
                                Ms. Palombo currently holds the position of Executive Vice President and Chief Human Resources Officer for Great-West Lifeco.
                                Prior to joining Great-West Life in 2014, she was an integral part of the senior leadership teams at TD Bank,
                                CanWest Media Inc., Husky Injection Molding, Union Gas and Canada Life. With a legal background and specialization in
                                human resources and compensation, Ms. Palombo has also served on several boards in the past, notably for
                                Student Transportation of America and Enercare Inc. In 2018, Ms. Palombo was named one of Canada's Top 100 Most Powerful Women
                                by the Women's Executive Network.
                            </p>
                        </div>
                    </div>
                </Row>
                <Row><div className="divider-middle"><hr /></div></Row>

                {/*BILL*/}
                <Row className="justify-content-start align-items-start">
                    <div className='board-content'>
                        <div className='img-container'><img className="board-image" src={BillImage} alt="William (Bill) Chyfetz" /> </div>
                        <div className='summary'>
                            <p className="name"> William (Bill) Chyfetz </p>
                            <p className="job-title"> Chair of the Audit Committee </p>
                            <p>
                                Mr. Chyfetz was Vice President and Secretary of Progressive Waste Solutions Ltd. (TSX: BIN) and its predecessors from
                                July 2000 to September 2014. He was also General Counsel from July 2000 to July 2010, and Associate General Counsel from
                                July 2010 to September 2014. Mr. Chyfetz is a Chartered Accountant with a CPA, CA designation as well as a barrister and
                                solicitor called to the Ontario bar. Mr. Chyfetz obtained his LL.B. from Osgoode Hall Law School and his B. Comm.
                                from McGill University. Mr. Chyfetz was the 2010 Canadian General Counsel Awards Mid-Market Excellence Award recipient.
                            </p>
                        </div>
                    </div>

                </Row>
                <Row><div className="divider-middle"><hr /></div></Row>

                {/*DAVID*/}
                <Row className="justify-content-start align-items-start">
                    <div className='board-content'>
                        <div className='img-container'><img className="board-image" src={DavidImage} alt="David Bradley" /> </div>
                        <div className='summary'>
                            <p className="name"> David Bradley, MA, BA</p>
                            <p className="job-title"> Chair of the Corporate Governance and Nominating Committee </p>
                            <p>
                                For the better part of three decades, Mr. Bradley was the voice of trucking in Canada and Ontario until his retirement
                                at the end of 2017 as the CEO of both the Canadian Trucking Alliance and the Ontario Trucking Association. During his tenure,
                                Mr. Bradley was responsible for leading several key industry policy initiatives including the introduction of mandatory
                                speed limiter activation, a universal ELD mandate and mandatory entry level training for truck drivers, to name a few.
                                Mr. Bradley is also a fellow of the Chartered Institute of Logistics and Transportation and serves on the Board of Directors
                                of Hamilton-Oshawa Port Authority, Great Lakes Port Management Inc., and is past-Chair of the Ontario Highway Transport Board.
                                He is a recipient of the Ontario National Transportation Week Person of the Year Award, the Ontario Ministry of Transportation
                                Road User Safety Divisional Partnership Award and the OTA Service to Industry Award.
                            </p>
                        </div>
                    </div>
                </Row>
                <Row><div className="divider-middle"><hr /></div></Row>

                

                

            </Container>

        </div>

    )
}

export default BoardOfDirectors
