import React from 'react';
import { List } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumb } from 'semantic-ui-react';
import '../styles/Logistics.css';
import LogisticsCarousel from '../components/LogisticsCarousel';


const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'services', content: 'SERVICES', href: "/services" },
    { key: 'logistics', content: 'LOGISTICS', active: true },
]


// function handleClick(event) {
//     event.preventDefault();
// }



function Logistics() {
    {/**breadcrumb */ }
    // const useStyles = makeStyles((theme) => ({
    //     root: {
    //         '& > * + *': {
    //             marginTop: theme.spacing(2),
    //         },
    //     },
    // }));


    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>

            <div className="services-header">Logistics</div>
            <div className="container-fluid services-container" id="warehousing-container">
                <div class="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="services-picture">
                            <LogisticsCarousel />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="services-text">
                            <h3>TITANIUM OFFERS YOU AN ARRAY OF SERVICES INCLUDING:</h3>
                            < br />
                            <ul>
                                <List.Item as='li'>Over the road FTL and LTL throughout North America</List.Item>
                                <List.Item as='li'>Live compliance monitoring of carriers through RMIS</List.Item>
                                <List.Item as='li'>Carrier tracking though the Titanium Trace App (Android & iOS)</List.Item>
                                <List.Item as='li'>Carrier tracking and connectivity through APIs</List.Item>
                                <List.Item as='li'>Carrier relationship management through the Titanium Fusion portal</List.Item>
                                <List.Item as='li'>Customized logistics programs available</List.Item>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Logistics
