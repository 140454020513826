import { Link } from 'react-router-dom';
import "../styles/SideMenu.css";
import SubMenu from './Submenu';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import React, { useState } from "react";



function SideMenuItem(props) {
    const [submenuOpen, setSubnav] = useState(false);

    const toggleSubmenu = () => setSubnav(!submenuOpen);

    const [item, index] = [props.item, props.index];


    // {item.cName}
    return (
        <>

            < li key={index} className={item.cName} onClick={toggleSubmenu}>
                <Link to={item.path} size='2em' className="main-menu-name">
                    {item.icon}
                    <span>{item.title}</span>
                {/* </Link>

                <Link to='#' className='sub-menu-icon'> */}
                    {item.subitem && !submenuOpen && <IoIosArrowDown className="dropdownIcon" />}

                    {item.subitem && submenuOpen && <IoIosArrowUp className="dropdownIcon"/>}

                </Link>
               
            </li >
            {item.subitem && submenuOpen &&
                <div className="subitem-container">
                
                    
                    <SubMenu className={submenuOpen ? "sub-menu active" : 'sub-menu'} item={item} key={index} toggleSidebar={props.toggleSidebar} />
                
                </div>}

        </>
    );
}
export default SideMenuItem


