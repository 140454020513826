import React from 'react'
import '../styles/LoadingPlaceholder.css';

function LoadingPlaceholder(props) {
    return (
        <>
            <div className='loading-placeholder'>
            {[...Array(parseInt(props.n))].map((_, idx) => (<div key={idx} className="linear-background">
                    <div className="inter-draw"></div>
                    <div className="inter-crop"></div>
                    <div className="inter-right--top"></div>
                    <div className="inter-right--bottom"></div>
                </div>))}
                
            </div>
        </>
    )
}
export default LoadingPlaceholder