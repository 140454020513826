import React, { useState, useEffect } from 'react';
import '../styles/NewsandPress.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Breadcrumb, Input } from "semantic-ui-react";
import Popup from './Popup';
import '../styles/Popup.css';
import moment from 'moment';
import { CDataTable } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css'
import { MdOutlineMarkEmailRead } from 'react-icons/md'
import LoadingPlaceholder from '../components/LoadingPlaceholder';


const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'services', content: 'NEWS & PRESS', active: true },
]

function handleClick(event) {
    event.preventDefault();
}
require('dotenv').config();

//// Load the AWS SDK for Node.js
//var AWS = require("aws-sdk");

//const SES_CONFIG = {
//    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
//    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//    region: 'us-east-1',
//};

//const AWS_SES = new AWS.SES(SES_CONFIG);

function NewsandPress() {

    const [isOpen, setIsOpen] = useState(false);
    const [topNews, setTopNews] = useState();

    const [allNews, setAllNews] = useState([]);

    const loadTopNews = () => {
        fetch(`https://news.ttgi.com/api/news/L?limit=10`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(newsData => {
                let data = JSON.parse(newsData.Data);
                let news = [];
                for (let i = 0; i < data.length; i++) {
                    news.push(
                        <div className="top-news border rounded-lg">
                            <div className="top-news-heading">
                                {data[i].Title}
                            </div>
                            <div className="top-news-date">
                                {moment(data[i].Date).format('MMMM D, YYYY')} - {data[i].By}
                            </div>
                            <div className="top-news-body">
                                {data[i].PreviewText}
                            </div>

                        </div>
                    )
                }
                setTopNews(news);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const loadAllNews = () => {
        fetch(`https://news.ttgi.com/api/news/A`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(newsData => {
                let data = JSON.parse(newsData.Data);
                setAllNews(data);
                setNewsStatus(true);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        {/**       loadTopNews(); */ }
        loadAllNews();
    }, []);

    useEffect(() => {
        //alert(topNews.length);
    }, [topNews]);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const fields = [{ key: 'singleNews', label: '' }];

    const [newsReady, setNewsStatus] = useState(false);




    const [subscriptionFormMessage, setSubscriptionFormMessage] = useState("");

    function sendEmail(e) {
        //https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/ses-examples-sending-email.html
        //https://betterprogramming.pub/how-to-send-emails-with-node-js-using-amazon-ses-8ae38f6312e4

        e.preventDefault();
        setSubscriptionFormMessage("");

        var emailBody = `${e.target.firstName.value} ${e.target.lastName.value} ${e.target.email.value} ${e.target.company.value}`;

        let body = {
            "subject": "Investors Subscription Request",
            "body": emailBody,
            "recipient": "investors@ttgi.com"
        }

        // create the headers for the fetch 
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Content-Type', 'application/json');

        // add the orders via api call
        fetch('https://api.ttgi.com/wwwEmail/send', {
            headers: myHeaders,
            method: "POST",
            body: JSON.stringify(body)
        }).then((response) => {
            //set the error message
            if (response.status != 200) {
                console.log(response);
                setSubscriptionFormMessage('You are successfully subscribed to Titanium News!');
            }
            //return response.json();
        })
            //.then(data => {
                
            //})
            .catch(function (error) {
                setSubscriptionFormMessage('There was an error processing your request. Please try again later or contact investors@ttgi.com.');
            });

        //// Create sendEmail params 
        //var params = {
        //    Destination: { /* required */
        //        CcAddresses: [
        //            // 'dev@ttgi.com',
        //            /* more items */
        //        ],
        //        ToAddresses: [
        //            'investors@ttgi.com'
        //            /* more items */
        //        ]
        //    },
        //    Message: { /* required */
        //        Body: { /* required */
        //            Html: {
        //                Charset: "UTF-8",
        //                Data: emailBody
        //            },
        //            Text: {
        //                Charset: "UTF-8",
        //                Data: emailBody
        //            }
        //        },
        //        Subject: {
        //            Charset: 'UTF-8',
        //            Data: 'Newsletter Subscription Form'
        //        }
        //    },
        //    Source: 'donotreply@ttgi.com', /* required */
        //    ReplyToAddresses: [
        //        'donotreply@ttgi.com',
        //        /* more items */
        //    ],
        //};

        //// Create the promise and SES service object
        //var sendPromise = AWS_SES.sendEmail(params).promise();

        //// Handle promise's fulfilled/rejected states
        //sendPromise.then(
        //    function (data) {
        //        console.log(data.MessageId);
        //        setSubscriptionFormMessage('You are successfully subscribed to Titanium News!');
        //    }).catch(
        //        function (err) {
        //            setSubscriptionFormMessage('There was an error processing your request. Please try again later or contact investors@ttgi.com.');
        //            console.error(err, err.stack);
        //        });

        e.target.reset();
    }

    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: "#FFF" }} />
            </div>
            <div className="news-header">
                <p><b>News & Press </b></p>
            </div>
            {/** 
                    <Container fluid className="news-ticker-container">
                        <Row className="justify-content-start align-items-start">
                            { topNews && <NewsTicker
                                rowHeight = {100}
                                maxRows = {3}
                                speed = {600}
                                direction = {Directions.UP}
                                duration = {4000}
                                autoStart = {true}
                                pauseOnHover = {false}
                                id = "myId"
                                className = "myClassName1 myClassName2"
                                style = {{marginTop: 0}}>
                                    {topNews}
                            </NewsTicker>
                            }
                        </Row>
                        <Row style={{ width: '100%', textAlign: 'right'}}>
                            <div className="subscribe-modal"><Link to='/subscribepopup'>Click here to subscribe to Titanium News</Link></div>
                        </Row>
                    </Container>
                    */}


            <Container fluid className="news-all-container" style={{ width: '900px' }}>
                <Row className="justify-content-start align-items-start">
                    <div id='news-header'>
                        <div className="table-header" style={{ textAlign: 'left', width: 'auto', fontWeight: '700', fontSize: '1.5rem', color: '#1d0643', float: 'left' }}>All News Articles</div>
                        <input className="subscribe-button"
                            type="button"
                            value="Subscribe the News"
                            onClick={togglePopup}
                        />
                        <MdOutlineMarkEmailRead id="subscribe-icon" type='button' onClick={togglePopup}> </MdOutlineMarkEmailRead>
                    </div>
                    {!newsReady && <LoadingPlaceholder n='4' />}
                    {newsReady &&
                        <CDataTable
                            items={allNews}
                            fields={fields}
                            itemsPerPage={10}
                            pagination={{ align: 'center' }}
                            hover
                            scopedSlots={{
                                'singleNews':
                                    (item, index) => {
                                        return (
                                            <>
                                                {true &&
                                                    <td className="py-2" style={{ textAlign: 'left' }} key={index}>
                                                        <div className="all-news">
                                                            <div className="all-news-date" style={{ fontSize: '0.9375rem' }}>
                                                                {moment(item.Date).format('MMMM D, YYYY')} - {item.By}
                                                            </div>
                                                            <div className="all-news-heading" style={{ fontSize: '0.9375rem' }}>
                                                                {item.Title}
                                                            </div>
                                                            <div className="read-more"><a href={item.Url} target="_blank">READ MORE {'>>'} </a>

                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                            </>
                                        )
                                    },
                            }}
                        />}
                </Row>
            </Container>

            {isOpen && <Popup className='subscribe-popup'
                content={<>
                    <div className="footer-email-form">
                        <form onSubmit={sendEmail}>
                            <Container fluid className="p-0 m-0">
                                <Row>
                                    <div className="popup-header">
                                        < br />
                                        < br />
                                        SUBSCRIBE TO TITANIUM NEWS
                                        < br />
                                        < br />
                                    </div>
                                    <Col md>
                                        First Name *
                                        <Input fluid size="small" labelPosition='right corner' placeholder="First name" className="noPadding" name="firstName" required="required" />
                                    </Col>
                                    <Col md>
                                        Last Name *
                                        <Input fluid size="small" labelPosition='right corner' placeholder="Last name" name="lastName" required="required" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md>
                                        Email Address *
                                        <Input fluid size="small" labelPosition='right corner' placeholder="Email Address" name="email" required="required" />
                                    </Col>

                                    <Col md>
                                        Company
                                        <Input fluid size="small" labelPosition='right corner' placeholder="Company" name="company" />
                                    </Col>
                                </Row>

                                <Row className="justify-content-end"><button className="btn-outline1" onClick={() => { }}>SUBSCRIBE</button></Row>
                                <Row><span style={{ fontSize: "0.8rem", fontStyle: "italic", textAlign: "center" }}>We treat your data confidentially and don't share any information with third parties.</span></Row>
                                <p style={{ fontSize: "0.9rem", fontStyle: "italic", textAlign: "center" }}>{subscriptionFormMessage}</p>
                            </Container>
                        </form>
                    </div>


                </>}
                handleClose={togglePopup}
            />}

        </>
    )
}

export default NewsandPress
