import React from 'react';
import DSImage from '../assets/DSImage.png';
import { makeStyles } from '@material-ui/core/styles';
import { List } from 'semantic-ui-react';
import '../styles/DigitalSolutions.css';
import { Breadcrumb } from 'semantic-ui-react';
import DSCarousel from '../components/DSCarousel';


const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'services', content: 'SERVICES', href: "/services" },
    { key: 'digitalsolutions', content: 'TECHNOLOGY', active: true },
]


function handleClick(event) {
    event.preventDefault();
}


function DigitalSolutions() {
    {/**breadcrumb */ }
    const useStyles = makeStyles((theme) => ({
        root: {
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    }));


    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>

            <div className="services-header">Technology</div>
            <div className="container-fluid services-container" id="warehousing-container">
                <div className="row">
                    <div id="technology-image-wrap">
                        <div className="services-picture" style= {{width: 'auto', height: ''}} >
                            <DSCarousel  />
                            
                            
                           {/**  <img src={DSImage} alt="Digital Solutions Services" style={{marginLeft: '170px'}}/> 
                           */}
                        </div>
                    </div>
                    <div id='technology-description-wrap'>
                        <div className="services-text">
                            <h3>TITANIUM UTILIZES ADVANCED TECHNOLOGY TO ENHANCE OPERATIONS:</h3>
                            < br />
                            <ul>
                                <List.Item as='li'>Customer connectivity though Electronic Data Interchange (EDI)</List.Item>
                                <List.Item as='li'>API and custom interface development for customers and carriers </List.Item>
                                <List.Item as='li'>Online warehouse inventory access for customers</List.Item>
                                <List.Item as='li'>Customer and carrier portals for load tracking and management</List.Item>
                                <List.Item as='li'>Digital equipment tracking solutions through using Isaac Instruments and Blackberry RADAR</List.Item>
                                <List.Item as='li'>Business intelligence reporting to optimize business operations</List.Item>
                                <List.Item as='li'>Information Technology and Information Systems dedicated to system reliability and enhancement</List.Item>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DigitalSolutions
