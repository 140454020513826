import { Carousel } from 'react-responsive-carousel';
import BlackBerry from '../assets/blackberry-radar.png';
import BlackBerry1 from '../assets/blackberry-radar1.png'
import Issac from '../assets/issac.PNG'
import Issac2 from '../assets/issac2.PNG'
import Issac3 from '../assets/issac3.PNG'

export default () => (
    <Carousel autoPlay>
        <div className="ds-image-one">
            <img src={BlackBerry} alt="BlackBerry Radar" />
        </div>
        <div className="ds-image-two">
            <img src={Issac} alt="image 2" />
        </div>
        <div className="ds-image-three">
            <img src={Issac2} alt="digital" />
        </div>
        <div className="ds-image-four">
            <img src={Issac3} alt="image 2" />
        </div>
        <div className="ds-image-five">
            <img src={BlackBerry1} alt="BlackBerry Radar" />
        </div>
    </Carousel>
)