import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Trucking from './pages/Trucking';
import Logistics from './pages/Logistics';
import Warehousing from './pages/Warehousing';
import DigitalSolutions from './pages/DigitalSolutions';
import BoardOfDirectors from './pages/BoardOfDirectors';
import WhistleBlowerPolicy from './pages/WhistleBlowerPolicy';
import ContactUs from './pages/ContactUs';
import Forms from './pages/Forms';
import FinancialReports from './pages/FinancialReports';
import DividendInformation from './pages/DividendInformation';
import RequestQuote from './pages/RequestQuote';
import NewsandPress from './pages/NewsandPress';
import Investors from './pages/Investors';
import AboutUs from './pages/AboutUs';
import Login from './pages/Login';
import Popup from './pages/Popup';
import MissionStatement from './pages/MissionStatement'
import MobileApp from './pages/MobileApp'
import MobileAppDrivers from './pages/MobileAppDrivers'
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";


function scriptContents() {
    alert();
}

function App() {
  return (
    <div className="App">
      <Router>
      <Switch>
        <Route path="/mobileAppDrivers" component={MobileAppDrivers}/>
        <Route path="/mobileApp*" component={MobileApp}/>
        <>
        <Navbar />
          <Route path="/" exact component={Home}/>
          <Route path="/services" exact component={Services} />
          <Route path="/trucking" exact component={Trucking} />
          <Route path="/logistics" exact component={Logistics} />
          <Route path="/warehousing" exact component={Warehousing} />
          <Route path="/digitalsolutions" exact component={DigitalSolutions} />
          <Route path="/whistleBlower" exact component={WhistleBlowerPolicy} />
          <Route path="/boardofdirectors" exact component={BoardOfDirectors} />
          <Route path="/contactus" exact component={ContactUs} />
          <Route path="/forms" exact component={Forms} />
          <Route path="/financialreports" exact component={FinancialReports} />
          <Route path="/dividendinformation" exact component={DividendInformation} />
          <Route path="/requestquote" exact component={RequestQuote} />
          <Route path="/newsandpress" exact component={NewsandPress} />
          <Route path="/investors" exact component={Investors} />
          <Route path="/aboutus" exact component={AboutUs} />
          <Route path="/login" exact component={Login} />
          <Route path="/subscribe" exact component={Popup} />
          <Route path="/missionstatement" exact component={MissionStatement} />
          <Route
            path="/careers"
            exact
            render={() => {
              window.location.href = "https://recruiting.ultipro.ca/TIT5000TTG/JobBoard/78b7a01b-cbed-4d4b-93ab-0a635eaebefb";
              return null; // Ensure nothing is rendered
            }}
          />
          {/* <Route
            path="/careers"
            exact
            render={() => {
              window.open(
                "https://recruiting.ultipro.ca/TIT5000TTG/JobBoard/78b7a01b-cbed-4d4b-93ab-0a635eaebefb",
                "_blank" // Opens in a new tab
              );
              return null; // No UI to render
            }}
          /> */}
          <Footer /> 
          </>
        </Switch>
        
      </Router>

      <script type='application/ld+json' dangerouslySetInnerHTML={ { __html: `
            { "@context" : "http://schema.org",
            "@type" : "Organization",
            "name" : "Titanium Transportation Group Inc",
            "url" : "https://www.ttgi.com",
            "sameAs" : [ "https://www.linkedin.com/company/titanium-transportation-group-inc",
                "https://www.facebook.com/TitaniumTransportationGroupInc/"] 
            }
            `}} />
    </div>

  );
}

export default App;
