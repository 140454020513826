import "../styles/SideMenu.css";
import React from "react";
import SideMenuItem from "./SideMenuItem"
import {MdWork} from "react-icons/md"


function SideMenuItemContainer(props) {
    const items = props.mainItems;

    return (
        <>
            <ul className='nav-main-menu-items'>
                {items.map((item, index) => {
                    return  <SideMenuItem item={item} key={index} index={index} sidebarOpen={props.sidebarOpen} toggleSidebar={props.toggleSidebar}/>
                })}
            < li className='nav-main-menu-text'>
                
                {/* External Career Page */}
                <a size='2em' className="main-menu-name" href="https://recruiting.ultipro.ca/TIT5000TTG/JobBoard/78b7a01b-cbed-4d4b-93ab-0a635eaebefb">
                    <MdWork />
                    <span>Careers</span>
                </a>
               
            </li >
            </ul>

        </>
    );
}
export default SideMenuItemContainer