import { Carousel } from 'react-responsive-carousel';
import TruckAtlanta from '../assets/AtlantaTruck.jpg';
import Trailers from '../assets/trailers.png';
import TruckFront from '../assets/truck-front.png'
import '../styles/Warehousing.css';

export default () => (
    <Carousel className="carousel" autoPlay>
        <div className="ds-image-one">
            <img src={TruckAtlanta} alt="digital" />
        </div>
        <div className="ds-image-two">
            <img src={Trailers} alt="image 2" />
        </div>
        <div className="ds-image-three">
            <img src={TruckFront} alt="Trailers" />
        </div>
    </Carousel>
)