import React from 'react';
import { AiOutlineHome, AiOutlineForm } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoIosPaper, IoMdInformationCircleOutline, IoIosPeople } from 'react-icons/io';
import { RiBuilding4Line, RiCustomerService2Line, RiContactsBook2Line, RiNewspaperLine } from 'react-icons/ri'
import { GiNetworkBars, GiTruck } from 'react-icons/gi'
import { BiMessageDetail } from 'react-icons/bi'
import { FaBoxes } from 'react-icons/fa'

export const SideMenuData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiOutlineHome />,
        cName: 'nav-main-menu-text'
    },
    {
        title: 'Service',
        path: '#',
        icon: <RiCustomerService2Line />,
        cName: 'nav-main-menu-text',
        subitem: [
            {
                title: 'Connectivity',
                path: '/digitalsolutions',
                icon: <GiNetworkBars />,
                cName: 'nav-sub-menu-text',
            },
            {
                title: 'Logistics',
                path: '/logistics',
                icon: <BiMessageDetail />,
                cName: 'nav-sub-menu-text',
            },
            {
                title: 'Trucking',
                path: '/trucking',
                icon: <GiTruck />,
                cName: 'nav-sub-menu-text',
            },
            {
                title: 'Warehousing',
                path: '/warehousing',
                icon: <FaBoxes />,
                cName: 'nav-sub-menu-text',
            },
        ]
    },
    {
        title: 'Investors',
        path: '#',
        icon: <HiOutlineDocumentReport />,
        cName: 'nav-main-menu-text',
        subitem: [
            {
                title: 'Board of Directors',
                path: '/boardofdirectors',
                icon: <IoIosPeople />,
                cName: 'nav-sub-menu-text',
            },
            {
                title: 'Dividend Information',
                path: '/dividendinformation',
                icon: <IoMdInformationCircleOutline />,
                cName: 'nav-sub-menu-text',
            },
            {
                title: 'Financial Reports',
                path: '/financialreports',
                icon: <HiOutlineDocumentReport />,
                cName: 'nav-sub-menu-text',
            },
        ]
    },
    {
        title: 'Forms',
        path: '#',
        icon: <IoIosPaper />,
        cName: 'nav-main-menu-text',
        subitem: [
            {
                title: 'Request A Quote',
                path: '/requestQuote',
                icon: <AiOutlineForm />,
                cName: 'nav-sub-menu-text',
            },
        ]
    },
    {
        title: 'About Us',
        path: '#',
        icon: <RiBuilding4Line />,
        cName: 'nav-main-menu-text',
        subitem: [
            {
                title: 'Contact Us',
                path: '/contactus',
                icon: <RiContactsBook2Line />,
                cName: 'nav-sub-menu-text',
            },
            {
                title: 'News & Press',
                path: '/newsandpress',
                icon: <RiNewspaperLine />,
                cName: 'nav-sub-menu-text',
            },
        ]
    },
];