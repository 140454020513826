
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import '../styles/Home.css';

function Player(props) {
    
    return (

        <Modal id="video-modal" open={props.open}  onClose={props.toggleModal} 
        center>
            <ReactPlayer
                url={props.link}
                height="700px" width="1000px"
                controls = {true}
                />
        </Modal>
    
    );
  }

export default Player;
