import { Carousel } from 'react-responsive-carousel';
import Digital from '../assets/DSImage.png';
import Logistics from '../assets/logistics.jpg'

export default () => (
    <Carousel autoPlay>
        <div className="ds-image-one">
            <img src={Digital} alt="digital" />
        </div>
        <div className="ds-image-two">
            <img src={Logistics} alt="image 2" />
        </div>



    </Carousel>
)