import React from "react";
import Player from "./Player.js"

function PopupVideo(props) {
    //const videoLink = "https://vimeo.com/687947777/ee08ec7b0b"; // old link
    const videoLink = "https://vimeo.com/821291753/eafb0b574a";

    return (
        <div>
          <Player link={videoLink}
           open={props.open} toggleModal={props.close} 
           />
        </div>
      );

}

export default PopupVideo
