import React from 'react';
import "../styles/Login.css";

export function LoginCards(props) {
    const style = {
        backgroundImage: "url(" + props.bgImage + ")",
    }
    return (
        <>

        <div className="login-card" style={style}>
            <div className="login-card-text">
                {props.name} <br />
                {props.name2}
            </div>
        </div>
        
        </>
    )
};

