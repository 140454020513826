import React, { useState } from "react";
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/Navbar.css";
import * as FaIcons from 'react-icons/fa';
import { AiOutlineClose, AiOutlineForm } from 'react-icons/ai';
import SideMenu from './SideMenu'
import { MdOutlineLogin } from 'react-icons/md'

function Navbar() {


    const history = useHistory();

    const gotoLogin = () => {
        history.push("/login");
    }
    const gotoRequest = () => {
        history.push("/requestQuote");
    }

    const [sidebarOpen, setSidebar] = useState(false);

    const toggleSidebar = () => {
        setSidebar(!sidebarOpen);
    }


    return (
        <div className="navbar" style={{ position: 'fixed' }}>

            <div className="row">
                <div className="navbarContainer" >
                    <div className="leftSide">
                        <Link alt="Site Home" to="/" ><img alt="Image Link To Site Home" src={Logo} className="titaniumLogo" /></Link>
                    </div>
                    <div id="navMenu" className="rightSide" >
                        <Link to="/services" >SERVICES</Link>
                        <Link to="/investors">INVESTORS</Link>
                        <Link to={{ pathname: "https://recruiting.ultipro.ca/TIT5000TTG/JobBoard/78b7a01b-cbed-4d4b-93ab-0a635eaebefb" }} target="_blank">CAREERS</Link>
                        <Link to="/contactus">CONTACT US</Link>
                        <Link to="/forms">FORMS</Link>
                    </div>
                </div>
                <div id="rightMenu">
                    <div className="float-right">
                        <button onClick={gotoRequest} className='button'>REQUEST A QUOTE</button>

                        {/* <button onClick={gotoLogin}  className='button'>CUSTOMER, CARRIER & WAREHOUSE LOGIN</button> */}
                        <Link to='/login' className='menu-bars'>
                            <MdOutlineLogin className='menu-icon' />
                        </Link>
                        <Link to='/requestQuote' className='menu-bars'>
                            <AiOutlineForm className='menu-icon' />
                        </Link>
                        <Link to='#' className='menu-bars'>
                            {!sidebarOpen ? (<FaIcons.FaBars className='menu-icon' onClick={toggleSidebar} />) : (<AiOutlineClose className='menu-icon' onClick={toggleSidebar} />)}
                        </Link>

                    </div>
                </div>


            </div>
            <SideMenu id="menu" sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        </div>

    );
}

export default Navbar
